<template>
    <div class="base-page">
        <div class="query-box">
            <el-form :inline="true" :model="order_query_form"  ref="order_query_form" class="demo-form-inline">

                <el-form-item label="房屋编号" prop="room_code">
                    <el-input  v-model="order_query_form.room_code" placeholder="请输入房屋编号"  size="mini"></el-input>
                </el-form-item>

                <el-form-item label="缴费渠道" prop="order_channel">
                    <el-select v-model="order_query_form.order_channel" placeholder="请选择"  size="mini">
                        <el-option
                                v-for="item in order_channel_options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="日期范围" prop="date_between">
                    <el-date-picker
                            size="mini"
                            v-model="order_query_form.date_between"
                            type="daterange"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :picker-options="pickerOptions"
                            @change="dateChang"
                    >
                    </el-date-picker>
                </el-form-item>

                <el-form-item>
                    <el-button size="mini" type="primary" @click="query">查询</el-button>
                    <el-button size="mini"  @click="resetForm('order_query_form')">重置</el-button>
                </el-form-item>
            </el-form>
        </div >

        <div class="base-body" id="base-body">
            <div class="base-table" >
                <el-table
                        :data="orderList"
                        border
                        :height="tableHeight"
                        v-loading="loading"
                >
                    <el-table-column type="expand">
                        <template slot-scope="props">
                            <el-form label-position="left" inline class="demo-table-expand">
                                <el-form-item label="订单编号">
                                    <span>{{ props.row.order_num }}</span>
                                </el-form-item>
                                <el-form-item label="创建时间">
                                    <span>{{ props.row.create_time }}</span>
                                </el-form-item>

                                <el-form-item label="数据来源">
                                    <span>{{ props.row.data_resource_text }}</span>
                                </el-form-item>

                            </el-form>
                        </template>
                    </el-table-column>
                    <el-table-column
                            v-for="item in columns"
                            :key="item.prop"
                            :prop="item.prop"
                            :label="item.label"
                            :width="item.width"
                            :formatter="item.formatter"
                            :fixed = "item.fixed"
                    >
                    </el-table-column>
                    <el-table-column label="操作" width="200" fixed="right">
                        <template #default="scope">

                            <el-button size="mini" type="text" @click="handleInfo(scope.row)"
                            >缴费详情</el-button
                            >
                            <el-button
                                    size="mini"
                                    type="text"
                                    @click="handlePrint(scope.row)"
                            >票据打印</el-button
                            >

                            <el-button v-if="scope.row.order_channel ==1"
                                    size="mini"
                                    type="text"
                                    @click="refund(scope.row)"
                            >退款</el-button
                            >

                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>


        <div class="base-footer">
            <el-pagination @current-change="handleCurrentChange"
                           @size-change = "handSizeChange"
                           :current-page="page"
                           :page-sizes="[10,20,50]"
                           :page-size="limit"
                           layout="total, sizes, prev, pager, next, jumper"
                           :total="count"
            >
            </el-pagination>
        </div>
<!--     缴费详情dialog   -->
        <el-dialog width="50%" height="80%"  top="none" title="缴费详情"
                   :visible.sync="dialog_order_info.open"
                   destroy-on-close
                   :close-on-click-modal="false"
        >
            <order_info
                    v-if="dialog_order_info.open"
                    :params="dialog_order_info.params"
                    v-on:childrenEvent="closeEvent"
            ></order_info>
        </el-dialog>


    </div>




</template>

<script>
    import order_info from "./order_info.vue";

    export default {
        name: "main_page",
        props:['upPageParams'],
        components:{
            order_info
        },
        data() {
            return {
                dialog_order_info:{
                    open:false,
                    params:''
                },

                loading:true,
                page:1,
                limit:20,
                count:0,

                order_query_form:{
                    room_code :'',
                    order_channel:'2',//默认小程序支付
                    date_between:[],
                    start_date:'',
                    end_date:''
                },
                order_channel_options: [{
                    value: '',
                    label: '全部'
                }, {
                    value: '2',
                    label: '小程序缴费'
                }, {
                    value: '1',
                    label: '线下缴费'
                }],


                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);

                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                tableHeight: 0,
                columns: [

                    {
                        label: "房屋编号",
                        prop: "room_code",
                        fixed: "left"
                    },
                    // {
                    //     label: "订单编号",
                    //     prop: "order_num",
                    //     width:"200"
                    // },
                    {
                        label: "缴费渠道",
                        prop: "order_channel",
                        width: '100',
                        formatter: (row, column, value) => {
                            let order_channel_text = '未知'
                            switch (value) {
                                case 1:
                                    order_channel_text = '线下'
                                    break;
                                case 2:
                                    order_channel_text="小程序"
                                    break
                            }

                            return order_channel_text;
                        },
                    },

                    {
                        label: '应收(元)',
                        prop: 'ys_money'
                    },
                    {
                        label: '优惠(元)',
                        prop: 'yh_money'
                    },
                    {
                        label: '实收(元)',
                        prop: 'sf_money'
                    },
                    {
                        label: '支付方式',
                        prop: 'pay_channel_text'
                    },
                    {
                        label: '缴费时间',
                        prop: 'pay_time',
                        width: '160'
                    },
                    {
                        label: '收费人',
                        prop: 'account_name',
                        width: 100
                    },
                    // {
                    //     label: '创建时间',
                    //     prop: 'create_time',
                    //     width: 160
                    // },
                ],
                orderList:[]
            }
        },
        created() {
            this.init()
        },
        mounted() {

            this.$nextTick(function() {
                this.tableHeight = document.getElementById("base-body").offsetHeight;
                this.query()
            })
        },
        methods:{
            init(){
                //初始化日期
                this.getCurrentMonthFirst()
                this.getCurrentMonthLast()
                if (this.order_query_form.date_between){
                    this.order_query_form.start_date = this.dayjs(this.order_query_form.date_between[0]).format("YYYY-MM-DD")
                    this.order_query_form.end_date = this.dayjs(this.order_query_form.date_between[1]).format("YYYY-MM-DD")
                }
            },
            //月初
            getCurrentMonthFirst () {
                var date = new Date()
                date.setDate(1)
                var month = parseInt(date.getMonth() + 1)
                var day = date.getDate()
                if (month < 10)  month = '0' + month
                if (day < 10)  day = '0' + day
                this.order_query_form.date_between[0] = date.getFullYear() + '-' + month + '-' + day

            },
            //当前时间
            getCurrentMonthLast () {
                var date = new Date()
                var month = parseInt(date.getMonth() + 1)
                var day = date.getDate()
                if (month < 10)  month = '0' + month
                if (day < 10)  day = '0' + day
                this.order_query_form.date_between[1] = date.getFullYear() + '-' + month + '-' + day

            },
            //日期变化
            dateChang(){
                if (this.order_query_form.date_between){
                    this.order_query_form.start_date = this.dayjs(this.order_query_form.date_between[0]).format("YYYY-MM-DD")
                    this.order_query_form.end_date = this.dayjs(this.order_query_form.date_between[1]).format("YYYY-MM-DD")
                }else {
                    this.order_query_form.start_date = ''
                    this.order_query_form.end_date = ''
                }
            },
            //查询
            query(){
                this.order_query_form.residence_id = this.upPageParams.commiunity.id
                this.order_query_form.page = this.page
                this.order_query_form.limit =this.limit

                let params = this.order_query_form
                this._postApi('/wy/orderSearch/list', params).then((res) => {
                    this.loading = false

                    if (res.code == 1) {

                        this.orderList = res.data.list
                        this.count = res.data.count
                    } else {

                        this.$confirm(res.msg, '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {

                        }).catch(() => {

                        });
                    }
                }).catch((err) => {
                    this.loading = false
                })


            },
            //重置
            resetForm() {
                this.order_query_form = {
                    room_code :'',
                    order_channel:'',
                    date_between:[],
                    start_date:'',
                    end_date:''
                }
                this.page=1
                this.query()
            },
           //page切换
            handleCurrentChange(val) {
                this.page = val;
                this.query();
            },
            //limit切换
            handSizeChange(val){

                this.limit = val;
                this.query();
            },

            //详情
            handleInfo(row){
                this.dialog_order_info.params=row
                this.dialog_order_info.open= true

            },

            //收据
            handlePrint(row){
                this._postApi('/wy/orderSearch/billPrint', {
                    oid: row.id
                })
                    .then(res => {
                        if (res.code == 1) {
                            window.open(res.data.url)
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    });
            },
            //退款
            refund(row){
                this.$confirm('退款后，本项缴费将变回未交费状态。点击确定，完成退款。', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let params = {
                        oid:row.id
                    }

                    this._postApi('/wy/orderSearch/offLineRefund', params)
                        .then(res => {
                            if (res.code == 1) {

                                this.query()
                                this.$notify({
                                    title: '提示',
                                    message: '操作成功',
                                    type: 'success'
                                });

                            } else {

                                this.$confirm(res.msg, '提示', {
                                        confirmButtonText: '确定',
                                        showCancelButton: false,
                                        type: 'warning'
                                    })
                                    .then(() => {
                                    })
                                    .catch(() => {
                                    });
                            }
                        })
                        .catch(err => {
                            console.log(err);
                        });
                }).catch(() => {

                });
            },
            closeEvent(e) {
                if (e.type == 'close') {
                    this.dialog_order_info.open = false;
                }
            },

        }
    }
</script>

<style scoped>
    .query-box{
        padding: 15px;
        display: flex;flex-direction: row;
    }
    .base-page{
        width: 100%;height: 100%;
        display: flex;flex-direction: column;
    }

    .base-body{
        flex: 1;width: 100%;
    }

   .base-table{
       padding: 0 15px;
       border-radius: 5px;
       background: #ffffff;
       height: 100%;width: 100%;


   }
    .base-footer{
        text-align: right;
        padding: 5px;
    }

    .demo-table-expand {
        font-size: 0;
    }
    .demo-table-expand label {
        width: 90px;
        /*color: #99a9bf;*/
    }
    .demo-table-expand .el-form-item {
        margin-right: 0;
        margin-bottom: 0;
        width: 50%;
    }

</style>
