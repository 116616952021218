<template >

    <div >

        <div class="page-header">
            <div class="item-f"> <span class="item-font">房屋编号：</span>{{params.room_code}}</div>
            <div class="item-f"><span class="item-font">订单号：</span>{{params.order_num}}</div>
            <div class="item-f"><span class="item-font">缴费时间：</span>{{params.pay_time}}</div>
        </div>

        <div class="page-body">
            <div v-for="item in order_info_list" v-bind:key="item.id" style="padding: 15px">
                <div >
                  <span class="item-font"> {{item.charge_month}}</span>
                </div>

                <template v-for="item1 in item.list">
                    <div v-bind:key="item1.id" class="item">
                        <div class="item-f">收费项目：{{item1.item_name}}</div>
                        <div class="item-f">应收：{{item1.ys_fy}}</div>

                        <div class="item-f">实收：{{item1.yj_fy}}</div>
                    </div>
                </template>

            </div>
        </div>

        <div class="page-footer">
            <el-button @click="clickCancel">关闭</el-button>
        </div>
    </div>


</template>

<script>
    export default {
        name: "order_info",
        props:['params'],
        data(){
            return{
                order_info_list:[]
            }
        },
        created() {
            console.log(this.params)
            this.getList();
        },
        methods:{
            getList(){
                let params = {
                  oid:this.params.id
                }

                this._postApi('/wy/orderSearch/info',params).then((res)=>{
                    if (res.code ==1){
                        this.order_info_list = res.data
                    }

                }).catch((err) => {
                  console.log(err)
                })
            },
            clickCancel(){
                this.$emit('childrenEvent', {
                    type: 'close'
                });
            }
        },
    }
</script>

<style scoped>
    .page-header{
        display: flex;justify-content: flex-start;align-items: center
    }
    .page-body{
        height: 60vh;overflow-y: auto;overflow-x: hidden;
    }
    .page-footer{
        text-align: right
    }
    .item{
        padding: 5px;display: flex;justify-content: flex-start;align-items: center
    }
    .item-f{
        padding: 5px 20px;
    }
    .item-font{
        color: #419DF7;font-weight: bold
    }
</style>
